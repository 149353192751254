import HTTP from "@/service/http";
import ticketoffice_session from "@/service/ticketoffice_session";
import session from "@/service/session";

let active_session = ticketoffice_session.getTicketofficeSession();

function saveSession(state) {
  ticketoffice_session.saveTicketofficeSession({
    updated: Date.now(),
    activeClient: state.activeClientData,
    selectedGame: state.selectedGameData,
    //selectedLottery: state.selectedLotteryData,
    currentPlayData: state.currentPlayData,
    is_online: state.ticketofficeOnLine
  });
}

function setGenerateGroupPayMethods(state) {
  if (state.payMethodsArray) {
    state.payMethodsArray.forEach((payMethod) => {
      state.payOptions.forEach(payOptionValidate => {
        if (
          (
            payMethod.name.indexOf(payOptionValidate.conditions.search) < 0 &&
            payMethod.currency_acronym === payOptionValidate.conditions.currencyAcronym &&
            payMethod.name.indexOf(payOptionValidate.conditions.notSearch)
          ) || (
            typeof payOptionValidate.conditions.keyword !== 'undefined' &&
            payMethod.keywords[0] === payOptionValidate.conditions.keyword
          )
        ) {
          const payOption = state.payOptions.filter((payOption) => payOption.title === payOptionValidate.title);

          if (payOption) {
            if (!payOption[0].monedaOptionByCodes.includes(payMethod.fk_currency)) {
              payOption[0].monedaOptionByCodes.push(payMethod.fk_currency);
            }

            if (!payOption[0].options.some((option) => option.id === payMethod.id)) {
              payOption[0].options.push(payMethod);
            }
          }
        }
      });
    });
  }
}

export default {
  namespaced: true,
  state: {

    /*
      TICKETOFFICE
    */
    showGameListOption: 0, /** game list show: 0 = hidden , 1 = show active games, 2 = show active services **/
    ticketofficeOnLine: true,
    enabledKeyboardInput: false,
    activeClientData: /* active_session && active_session.activeClient
        ? active_session.activeClient
        : */ {},
    selectedGameData:
      active_session && active_session.selectedGame
        ? active_session.selectedGame
        : {},
    selectedLotteryData: null,
    /* active_session && active_session.selectedLottery
        ? active_session.selectedLottery
        : null, */
    currentPlayData: /* active_session && active_session.currentPlayData
        ? active_session.currentPlayData
        : */ {
      ticket: null,
      plays: [],
      pays: [],
    },
    game_service_request: null,
    is_processing: false,
    is_processing_pay: false,
    monedaOptions: [
      {
        icon: "pi pi-money-bill",
        value: "VES",
        text: "Bolívares",
        exchange_rate: 5.2,
        code: 1,
      },
      {
        icon: "pi pi-dollar",
        value: "USD",
        text: "Dólares",
        exchange_rate: 1.0,
        code: 2,
        disable: false,
      },
      {
        icon: "pi pi-euro",
        value: "EUR",
        text: "Euros",
        exchange_rate: 0.95,
        code: 3,
        disable: false,
      },
      {
        icon: "pi pi-dollar",
        value: "BUSD",
        text: "USD",
        exchange_rate: 1.0,
        code: 4,
        disable: false,
        type: "crypto",
      },
    ],
    payOptions: [
      {
        title: "Pago en Efectivo",
        monedaOptionByCodes: [],
        notificationRequiresPaymentApproval: false,
        options: [],
        conditions: {
          keyword: "Efectivo",
        }
      },
      {
        title: "Pago en Criptomonedas",
        monedaOptionByCodes: [],
        notificationRequiresPaymentApproval: true,
        options: [],
        conditions: {
          currencyAcronym: "BUSD",
          search: "Cr",
          notSearch: "Efectivo"
        },
      },
      {
        title: "Pago en Bolívares",
        monedaOptionByCodes: [],
        notificationRequiresPaymentApproval: false,
        options: [],
        conditions: {
          currencyAcronym: "VES",
          search: "Tarjeta",
          notSearch: "Efectivo"
        },
      },
      {
        title: "Pago en Divisas",
        monedaOptionByCodes: [],
        notificationRequiresPaymentApproval: true,
        options: [],
        conditions: {
          currencyAcronym: "USD",
          search: "Tarjeta",
          notSearch: "Efectivo"
        },
      },
      {
        title: "Pago con créditos",
        monedaOptionByCodes: [],
        notificationRequiresPaymentApproval: false,
        options: [],
        conditions: {
          keyword: "crédito",
          currencyAcronym: "BUSD",
          search: "créditos",
          notSearch: "Efectivo"
        }
      },
    ],
    binancePayOrder: [],
    payMethods: [],
    payMethodsArray: [],
    clientPayMethods: [],
    payMethodsDetail: [],
    clientPayMethodsDetail: [],
    depositCreditOrder: [],
    userBalance: [],
    games_list: [],
    clientDialog: false,
    showConsultClient: false,
    payDialog: false,
    showTicketPreview: false,
    openModelConsultPaymentOrders: false,
    openModalBalanceWithdrawal: false,
    clientPayOrders: [],
    showPrint: false,
  },
  actions: {
    // Carga una orden de pago desde el serial, para ser pagada
    loadPaymentServicesSession({ commit, state}, data) {
      if (data.payorder_serial) {
        return new Promise((resolve, reject) => {
          HTTP(true, 60000)
            .get(`/payorders/external-services/${data.payorder_serial}`)
            .then((resp) => {
              if (resp.data && resp.data.data.id) {
                state.activeClientData = resp.data.data.fk_client;
                state.currentPlayData.ticket = {
                  created_at: resp.data.data.created_at,
                  event_datetime: resp.data.data.competitors[0].event_datetime,
                  fk_client: resp.data.data.fk_client,
                  fk_game: resp.data.data.fk_game,
                  id: resp.data.data.id,
                  serial: resp.data.data.serial,
                  sub_total: resp.data.data.sub_total,
                  total: resp.data.data.total,
                  debt: resp.data.data.total_due,
                  total_tax: resp.data.data.total_tax,
                };
                state.currentPlayData.plays = [...resp.data.data.competitors];
                state.currentPlayData.pays = [
                  ...resp.data.data.fk_transactions,
                ];
                commit("saveTicketofficeSession");
                if(state.currentPlayData.ticket.debt > 0)
                  commit("setPayDialog", true);
                else
                  commit("setShowPrint", true);
              } else {
                commit("clearCurrentPlayData");
                commit("setActiveClient", {});
              }
              resolve(resp);
            })
            .catch((err) => {
              reject(err);
            });
        });
      }
    },
    // Carga la sesión de taquilla, si existe, con la ultima orden de pago que se estaba procesando
    loadTicketOfficeSession({ commit, state, rootGetters }) {
      //Validar si la sesión es taquilla en línea o es de un usuario registrado o taquilla física
      state.ticketofficeOnLine = (!session.userIsLogged() || !(["taquilla", "usuario"].includes(session.getUserData().rol)));

      if (!state.ticketofficeOnLine)
        return new Promise((resolve, reject) => {
          HTTP(false, 60000)
            .get(
              ["taquilla"].includes(rootGetters["auth/role"])
                ? `/ticketoffices/last-pay-order`
                : `/persons/last-pay-order`
            )
            .then((resp) => {
              if (resp.data && resp.data.data.id) {
                state.activeClientData = resp.data.data.fk_client;
                // state.currentPlayData.ticket = {
                //   // created_at: resp.data.data.created_at,
                //   // event_datetime: resp.data.data.competitors[0].event_datetime,
                //   // fk_client: resp.data.data.fk_client,
                //   // fk_game: resp.data.data.fk_game,
                //   // id: resp.data.data.id,
                //   // serial: resp.data.data.serial,
                //   // sub_total: resp.data.data.sub_total,
                //   // total: resp.data.data.total,
                //   // debt: resp.data.data.total_due,
                //   // total_tax: resp.data.data.total_tax,

                //   //competitors_available: resp.data.data.game_detail.competitors_available,
                // };
                // state.currentPlayData.plays = [
                //   // ...resp.data.data.competitors
                // ];
                // state.currentPlayData.pays = [
                //   // ...resp.data.data.fk_transactions,
                // ];
                commit("saveTicketofficeSession");
              } else {
                commit("clearCurrentPlayData");
                commit("setActiveClient", {});
                if (!["taquilla"].includes(rootGetters["auth/role"])) {
                  state.activeClientData = resp.data.data.fk_client;
                }
              }
              resolve(resp);
            })
            .catch((err) => {
              reject(err);
            });
        });
      else {
        if (
          active_session &&
          active_session.updated &&
          active_session.is_online &&
          (Date.now() - active_session.updated) / 1000 / 60 <= 30
        ) {
          state.activeClientData =
            active_session && active_session.activeClient
              ? active_session.activeClient
              : {};
          state.selectedGameData = active_session.selectedGame
            ? active_session.selectedGame
            : {};
          state.selectedLotteryData = active_session.selectedLottery
            ? active_session.selectedLottery
            : null;
          state.currentPlayData = active_session.currentPlayData
            ? active_session.currentPlayData
            : {
                ticket: null,
                plays: [],
                pays: [],
              };
        }
      }
    },
    // Consulta la información de un cliente
    consultClient(_s, data) {
      return new Promise((resolve, reject) => {
        HTTP()
          .get(`/persons/publics/${data.dni}`)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    creditPay({commit}, data) {
      commit("setIsProcessingPay", true);
      const sendData = {
        amount: data.amount,
        fk_payment_method: data.fk_payment_method,
        banco_benef: data.banco_benef,
        telf_benef: data.telf_benef,
      }
      return new Promise((resolve, reject) => {
        HTTP()
          .post(`/transactions/credit-pay-order/${data.id}`, sendData)
          .then((resp) => {
            this.newAmount = Number(resp.data.data.amount).toFixed(9)
            commit("updatePayData", {
              total_debt: resp.data.data.total_due,
              pay_info: {
                fk_payment_method_detail: resp.data.data.fk_payment_method_detail,
                amount: this.newAmount,
                currency_id: resp.data.data.currency_id,
                fk_payment_method_id: resp.data.data.fk_payment_method_id
              },
            });
            commit("saveTicketofficeSession");
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => commit("setIsProcessingPay", false));
      });
    },
    creditClientPay({commit}, data) {
      commit("setIsProcessingPay", true);
      const sendData = {
        payorder_id: data.id,
        amount: Number(data.amount),
      }
      return new Promise((resolve, reject) => {
        HTTP(false)
          .post("/transactions/client-credit-pay-order", sendData)
          .then((resp) => {
            // this.newAmount = Number(resp.data.data.amount).toFixed(9)
            // commit("updatePayData", {
            //   total_debt: resp.data.data.total_due,
            //   pay_info: {
            //     fk_payment_method_detail: resp.data.data.fk_payment_method_detail,
            //     amount: this.newAmount,
            //     currency_id: resp.data.data.currency_id,
            //     fk_payment_method_id: state.payMethodsDetail.payMethodsDetail.data[0].fk_payment_method
            //   },
            // });
            commit("saveTicketofficeSession");
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => commit("setIsProcessingPay", false));
      });
    },
    verificationSMSCode({commit}, data) {
      commit("setIsProcessingPay", true);
      const sendData = {
        amount: Number(data.amount),
      }
      return new Promise((resolve, reject) => {
        HTTP()
          .put(`/transactions/client-credit-pay-order/${data.id}`, sendData)
          .then((resp) => {
            // this.newAmount = Number(resp.data.data.amount).toFixed(9)
            // commit("updatePayData", {
            //   total_debt: resp.data.data.total_due,
            //   pay_info: {
            //     fk_payment_method_detail: resp.data.data.fk_payment_method_detail,
            //     amount: this.newAmount,
            //     currency_id: resp.data.data.currency_id,
            //     fk_payment_method_id: state.payMethodsDetail.payMethodsDetail.data[0].fk_payment_method
            //   },
            // });
            commit("saveTicketofficeSession");
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => commit("setIsProcessingPay", false));
      });
    },
    validateClientWalletCodePay({commit} , data) {
      commit("setIsProcessingPay", true);
      const sendData = {
        verification_code: data.verification_code,
      }
      return new Promise((resolve, reject) => {
        HTTP()
          .patch(`/transactions/client-credit-pay-order/${data.id}`, sendData)
          .then((resp) => {
            this.newAmount = Number(resp.data.data.amount).toFixed(9)
            commit("updatePayData", {
              total_debt: resp.data.data.total_due,
              pay_info: {
                fk_payment_method_detail: resp.data.data.fk_payment_method_detail,
                amount: this.newAmount,
                currency_id: resp.data.data.currency_id,
                fk_payment_method_id: resp.data.data.fk_payment_method_id
              },
            });
            commit("saveTicketofficeSession");
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => commit("setIsProcessingPay", false));
      });
    },
    consultClientData({ commit }, data) {
      return new Promise((resolve, reject) => {
        HTTP()
          .get(`/persons/publics/${data.dni}`)
          .then((resp) => {
            commit("setActiveClient", resp.data.data);
            commit("saveTicketofficeSession");
            resolve(resp);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    consultClientPayOrdersData({ commit }, data) {
      return new Promise((resolve, reject) => {
        HTTP(true)
          .get("/payorders/latest", { params: data })
          .then((resp) => {
            commit("setClientPayOrders", resp.data.data);
            resolve(resp);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    registerClientData({ commit }, data) {
      return new Promise((resolve, reject) => {
        HTTP()
          .post("/persons/publics", data)
          .then((resp) => {
            commit("setActiveClient", resp.data.data);
            commit("saveTicketofficeSession");
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    registerPlayOrder({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        HTTP(state.ticketofficeOnLine, 500000)
          .post("/payorders", data)
          .then((resp) => {
            commit("setCurrentPlayData", resp.data.data);
            commit("saveTicketofficeSession");
            commit("setPayDialog", true);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    addCompetitorsToPlayOrder({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        HTTP(state.ticketofficeOnLine, 500000)
          .post(`/payorders/${data.order_id}/products`, data.data)
          .then((resp) => {
            commit("addCurrentPlayData", resp.data.data);
            commit("saveTicketofficeSession");
            commit("setPayDialog", true);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    registerPay({ commit, state }, data) {
      commit("setIsProcessingPay", true);
      return new Promise((resolve, reject) => {
        HTTP(true, 60000)
          .post("/transactions/product-pay-order/" + data.order_id, data.data)
          .then((resp) => {
            commit("updatePayData", {
              total_debt: resp.data.data.total_due,
              pay_info: {
                fk_payment_method_detail: resp.data.data.fk_payment_method_detail,
                amount: data.data.amount,
                currency_id: resp.data.data.currency_id,
                fk_payment_method_id: state.payMethodsDetail.payMethodsDetail.data[0].fk_payment_method
              },
            });
            commit("saveTicketofficeSession");
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => commit("setIsProcessingPay", false));
      });
    },
    //Temporal
    registerBNBPay({ commit }, data) {
      commit("setIsProcessingPay", true);
      return new Promise((resolve, reject) => {
        HTTP(true, 60000)
          .post("/transactions/product-pay-order/" + data.order_id, data.data)
          .then((resp) => {
            commit("updatePayData", {
              total_debt: resp.data.data.total_due,
              pay_info: {
                fk_payment_method_detail: resp.data.data.fk_payment_method_detail,
                amount: data.data.amount,
                currency_id: resp.data.data.currency_id,
                fk_payment_method_id: 2
              },
            });
            commit("saveTicketofficeSession");
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => commit("setIsProcessingPay", false));
      });
    },
    //Temporal
    cancelPlayOrder({ commit }, order_id) {
      commit("setIsProcessing", true);
      return new Promise((resolve, reject) => {
        HTTP(false, 60000)
          .delete(`/payorders/${order_id}`)
          .then((resp) => {
            commit("clearCurrentPlayData");
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => commit("setIsProcessing", false));
      });
    },
    cancelPlayOrderItem({ commit }, data) {
      commit("setIsProcessing", true);
      return new Promise((resolve, reject) => {
        HTTP(true, 60000)
          .delete(`/payorders/${data.order_id}/products/${data.id}`)
          .then((resp) => {
            commit("deletePlayOrderItem", data.id);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => commit("setIsProcessing", false));
      });
    },
    generatePayMethods({ commit }) {
      return new Promise((resolve, reject) => {
        HTTP(false, 60000)
          .get(`/paymentmethods?limit=100`)
          .then((resp) => {
            commit("setGeneratePayMethods", {
              payMethods: resp.data,
            });
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    generateClientPayMethods({ commit }) {
      return new Promise((resolve, reject) => {
        HTTP(false, 60000)
          .get(`/users/profile/bank-detail`)
          .then((resp) => {
            commit("setGenerateClientPayMethods", {
              clientPayMethods: resp.data,
            });
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    generatePayMethodsDetail({ commit }, data) {
      return new Promise((resolve, reject) => {
        HTTP(false, 60000)
          .get(`/paymentmethods/${data}`)
          .then((resp) => {
            commit("setGeneratePayMethodsDetail", {
              payMethodsDetail: resp.data,
            });
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    deletePayMethodsDetailData({ commit }) {
      commit("clearPayMethodsDetail");
    },
    generateClientPayMethodsDetail({ commit }, data) {
      return new Promise((resolve, reject) => {
        HTTP(false, 60000)
          .get(`/users/profile/bank-detail/${data}`)
          .then((resp) => {
            commit("setGenerateClientPayMethodsDetail", {
              clientPayMethodsDetail: resp.data,
            });
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    generateBinanceOrder({ commit }, data) {
      commit("setIsProcessingPay", true);
      return new Promise((resolve, reject) => {
        HTTP(false, 12000)
          .post("/transactions/binancepay", data)
          .then((resp) => {
            commit("setBinancePayOrder", {
              universal_url: resp.data.data.binancepay.universal_url,
              qrcode_link: resp.data.data.binancepay.qrcode_link,
              transaction: resp.data.data.transaction.id,
            });
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => commit("setIsProcessingPay", false));
      });
    },
    validateBinanceOrder({ commit, state }, data) {
      commit("setIsProcessingPay", true);
      return new Promise((resolve, reject) => {
        HTTP(false, 12000)
          .post("/transactions/binancepay", data)
          .then((resp) => {
            commit("updatePayData", {
              total_debt: resp.data.data.total_due,
              pay_info: {
                fk_payment_method_detail: resp.data.data.fk_payment_method_detail,
                amount: data.data.amount,
                currency_id: state.monedaOptions.filter((monedaOption) => monedaOption.value === 'BUSD')[0].code,
                fk_payment_method_id: state.payMethodsDetail.payMethodsDetail.data[0].fk_payment_method
              },
            });
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => commit("setIsProcessingPay", false));
      });
    },
    registerBinancePay({ commit, state }, data) {
      commit("setIsProcessingPay", true);
      return new Promise((resolve, reject) => {
        HTTP(true)
          .get(`/transactions/binancepay/${data.transaction_id}`)
          .then((resp) => {
            commit("updatePayData", {
              total_debt: resp.data.data.get_total_due,
              pay_info: {
                fk_payment_method_detail: resp.data.data.transaction.fk_payment_method_detail,
                amount: resp.data.data.transaction.amount,
                currency_id: state.monedaOptions.filter((monedaOption) => monedaOption.value === 'BUSD')[0].code,
                fk_payment_method_id: state.payMethodsDetail.payMethodsDetail.data[0].fk_payment_method
              },
            });
            commit("saveTicketofficeSession");
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => commit("setIsProcessingPay", false));
      });
    },
    sendPlayOrderTicketNotification(_s, data) {
      return new Promise((resolve, reject) => {
        HTTP(true)
          .post(`/payorders/add-notifications/${data.serial}`, {
            type_notification_id: data.type_notification_id,
          })
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    creditDepositRequest({ commit }, data) {
      return new Promise((resolve, reject) => {
        HTTP()
          .post("/transactions/deposit", data)
          .then((resp) => {
            commit("setCreditDepositRequest", {
              universal_url: resp.data.data.binancepay.universal_url,
              qrcode_link: resp.data.data.binancepay.qrcode_link,
              transaction: resp.data.data.transaction.id,
            });
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    creditWithdrawalRequest(_s, data) {
      return new Promise((resolve, reject) => {
        HTTP(false)
          .post("/transactions/withdraw", data)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    creditTransferRequest(_s, data) {
      return new Promise((resolve, reject) => {
        HTTP(false)
          .post("/transactions/transfer", data)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getCurrencies({ state }) {
      HTTP(true)
        .get("/currencies")
        .then((resp) => {
          state.monedaOptions.forEach((e) => {
            let item = resp.data.data.find((o) => o.acronym === e.value);
            if (item) {
              e.text = item.name;
              e.exchange_rate = parseFloat(item.currency_rate.value);
              e.code = item.id;
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getActiveOperatorGames({ commit }) {
      return new Promise((resolve, reject) => {
        HTTP(true)
          .get("/operators/games/publics")
          .then((resp) => {
            // resp.data.data.find(e => e.name === "Jungla Dinamica").url_front = `${process.env.VUE_APP_URL_NAME}iframes/game`
            // resp.data.data.find(e => e.name === "Jungla Dinamica").url_front = `${process.env.VUE_APP_URL_NAME}/iframes/game`
            commit("setActiveGameList", resp.data.data);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getUserBalance({ commit }, data) {
      if (session.userIsLogged())
        return new Promise((resolve, reject) => {
          HTTP()
            .get("/users/get-balance", data)
            .then((resp) => {
              commit("setGetUserBalance", {
                balance: resp.data.data.user_balance,
              });
              resolve(resp);
            })
            .catch((err) => {
              reject(err);
            });
        });
    },
    clearCreditSession({ commit }) {
      commit("setClearCreditSession");
    },
    toggleModelConsultPaymentOrders({ commit, state }) {
      commit("setModelConsultPaymentOrders", !state.openModelConsultPaymentOrders);
    },
    toggleModalBalanceWithdrawal({ commit, state }) {
      commit("setModalBalanceWithdrawal", !state.openModalBalanceWithdrawal);
    },
    resendNotification(_s, data) {
      return new Promise((resolve, reject) => {
        HTTP(true)
          .post("/payorders/resend-notification", data)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    registerPromotion(_s, data) {
      return new Promise((resolve, reject) => {
        HTTP(true)
          .post(`/operators/games/promotions`, data)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },
  mutations: {
    setShowGameListOption: (state, value) => {
      state.showGameListOption = value;
    },
    setEnabledKeyboardInput: (state, value) => {
      state.enabledKeyboardInput = value;
    },
    setActiveGameList(state, data) {
      state.games_list = data;
      if(!state.games_list || !state.games_list.length || (state.selectedGameData && !state.selectedGameData.find(e => e.id === state.selectedGameData.id)))
        state.selectedGameData = {};
    },
    setSelectedGame: (state, data) => {
      state.selectedGameData = data;
    },
    setSelectedLottery: (state, data) => {
      state.selectedLotteryData = data;
    },
    saveTicketofficeSession: (state) => {
      saveSession(state);
    },
    setActiveClient: (state, data) => {
      state.activeClientData = data;
      saveSession(state);
    },
    setClientPayOrders: (state, data) => {
      state.clientPayOrders = data;
    },
    setCurrentGameServiceRequest: (state, data) => {
      state.game_service_request = data;
    },
    setIsProcessing: (state, value) => {
      state.is_processing = value;
    },
    setIsProcessingPay: (state, value) => {
      state.is_processing_pay = value;
    },
    setBinancePayOrder: (state, data) => {
      state.binancePayOrder = data;
    },
    setCurrentPlayData: (state, data) => {
      state.currentPlayData.ticket = {
        created_at: data.created_at,
        event_datetime: data.competitors[0].event_datetime, //data.game_detail.event_datetime,
        fk_client: data.fk_client,
        fk_ticketoffice: data.fk_ticketoffice,
        fk_game: data.fk_game,
        id: data.id,
        serial: data.serial,
        sub_total: data.sub_total,
        total: data.total,
        debt: data.total,
        total_tax: data.total_tax,

        //competitors_available: data.game_detail.competitors_available,
      };
      state.currentPlayData.plays = [...data.competitors];
      state.currentPlayData.pays = [];
      saveSession(state);
    },
    addCurrentPlayData: (state, data) => {
      state.currentPlayData.ticket.sub_total = data.sub_total;
      state.currentPlayData.ticket.total = data.total;
      state.currentPlayData.ticket.debt = data.total_due;
      state.currentPlayData.ticket.total_tax = data.total_tax;
      state.currentPlayData.plays = [
        ...data.competitors,
        ...state.currentPlayData.plays,
      ];
      state.currentPlayData.pays = [];
      saveSession(state);
    },
    clearCurrentPlayData: (state) => {
      state.currentPlayData = {
        ticket: null,
        plays: [],
        pays: [],
      };
      state.binancePayOrder = {};
      saveSession(state);
      state.showTicketPreview = false;
    },
    deletePlayOrderItem: (state, id) => {
      let item = state.currentPlayData.plays.find((val) => val.id === id);
      if (item) {
        state.currentPlayData.ticket.total -= item.price;
        state.currentPlayData.ticket.debt -= item.price;
        state.currentPlayData.ticket.sub_total -= item.price;
      }
      state.currentPlayData.plays = state.currentPlayData.plays.filter(
        (val) => val.id !== id
      );
      saveSession(state);
    },
    updatePayData: (state, data) => {
      state.currentPlayData.pays.push(data.pay_info);
      state.currentPlayData.ticket.debt = data.total_debt;
      saveSession(state);
    },
    setCreditDepositRequest: (state, data) => {
      state.depositCreditOrder = data;
    },
    setGetUserBalance: (state, data) => {
      state.userBalance = data;
    },
    setGeneratePayMethods: (state, data) => {
      data.payMethods.data = data.payMethods.data.map((payMethod) => {
        const monedaOption = state.monedaOptions.filter((monedaOption) => monedaOption.value === payMethod.currency_acronym);

        payMethod.icon = monedaOption[0].icon;
        payMethod.currency_name = monedaOption[0].text;

        return payMethod;
      });

      state.payMethods = data;
      state.payMethodsArray = data.payMethods.data;

      setGenerateGroupPayMethods(state);
    },
    setGenerateClientPayMethods: (state, data) => {
      state.clientPayMethods = data;
    },
    setGeneratePayMethodsDetail: (state, data) => {
      state.payMethodsDetail = data;
    },
    setGenerateClientPayMethodsDetail: (state, data) => {
      state.clientPayMethodsDetail = data;
    },
    clearPayMethodsDetail: (state) => {
      state.payMethodsDetail = [];
    },
    setClientDialog: (state, value) => {
      state.clientDialog = value;
    },
    setShowConsultClient: (state, value) => {
      state.showConsultClient = value;
      state.clientDialog = value;
    },
    setPayDialog: (state, value) => {
      state.payDialog = value;
    },
    setToggleTicketPreview: (state, value) => {
      state.showTicketPreview = value;
    },
    setModelConsultPaymentOrders: (state, value) => {
      state.openModelConsultPaymentOrders = value;
    },
    setModalBalanceWithdrawal: (state, value) => {
      state.openModalBalanceWithdrawal = value;
    },
    setShowPrint: (state, value) => {
      state.showPrint = value;
    },
  },
  getters: {
    activeClient: (state) => {
      return state.activeClientData;
    },
    selectedGame: (state) => {
      return state.selectedGameData;
    },
    selectedLottery: (state) => {
      return state.selectedLotteryData;
    },
    currentPlayData: (state) => {
      return state.currentPlayData;
    },
    activeGameList: (state) => {
      return state.games_list ? state.games_list : [];
    },
    currentGameServiceRequest: (state) => {
      return state.game_service_request;
    },
    currentPlayDataPlayList: (state) => {
      return state.currentPlayData && state.currentPlayData.plays
        ? state.currentPlayData.plays.reduce(function (rv, x) {
            let item = rv.find(
              (e) =>
                e.fk_game === x.fk_game && e.category_code === x.category_code
            );
            if (item) {
              item.plays.push(x);
            } else rv.push({ fk_game: x.fk_game, category_code: x.category_code, event_datetime: x.event_datetime, plays: [x] });
            return rv;
          }, [])
        : [];
    },
    phonePrimary: (state) => {
      const activeClient = state.activeClientData;
      let phone = "";

      if (activeClient.fk_contact && activeClient.fk_contact.phone_number) {
        phone = activeClient.fk_contact.phone_number;
      }

      return phone;
    },
    phoneWhatsapp: (state, getters) => {
      const activeClient = state.activeClientData;
      let phone = getters.phonePrimary;

      if (activeClient.fk_contact && activeClient.fk_contact.whatsapp) {
        phone = activeClient.fk_contact.whatsapp;
      }

      return phone;
    },
    phoneTelegram: (state, getters) => {
      const activeClient = state.activeClientData;
      let phone = getters.phonePrimary;

      if (activeClient.fk_contact && activeClient.fk_contact.telegram) {
        phone = activeClient.fk_contact.telegram;
      }

      return phone;
    },
    getOpenModelConsultPaymentOrders: (state) => {
      return state.openModelConsultPaymentOrders;
    },
    getOpenModalBalanceWithdrawal: (state) => {
      return state.openModalBalanceWithdrawal;
    },
    getTotalUserBalance: (state) => {
      return state.userBalance?.balance ?? 0;
    },
  },
};
