<template>
  <div class="p-0 m-0">
    <div class="card-container">
      <div
        class="flex flex-shrink transaction-general"
        v-if="paymethod_selected"
      >
        <form
          class="flex-1 inline-block pt-3"
          @submit.prevent="sendPaymentRequest(!v$.$invalid)"
          autocomplete="off"
          aria-autocomplete="off"
        >
          <div v-if="!withdraw">
            <div class="field text-start">
              <label
                for="transfer-payment-reference"
                class="flex ml-3"
                :class="{
                  'pl-3 p-error': v$.reference.$invalid && submitted,
                }"
              >
                Código de Referencia <span class="text-red-500">*</span>
              </label>
              <span class="p-float-label">
                <InputMask
                  id="reference"
                  mask="9999"
                  slotChar=""
                  inputmode="numeric"
                  pattern="[0-9]*"
                  autocomplete="off"
                  aria-autocomplete="off"
                  autofocus
                  :useGrouping="false"
                  v-model="v$.reference.$model"
                  :class="{
                    'p-border-button-invalid':
                      v$.reference.$invalid && submitted,
                  }"
                />
                <div
                  v-if="
                    (v$.reference.$invalid && submitted) ||
                    v$.reference.$pending.$response
                  "
                >
                  <small class="p-error">Código de referencia requerido.</small>
                </div>
              </span>
            </div>

            <!-- Phone  -->
            <div class="field text-start">
              <label
                for="transfer-payment-phone"
                class="flex ml-3"
                :class="{
                  'pl-3 p-error': v$.phone.$invalid && submitted,
                }"
              >
                Numero de telefono <span class="text-red-500">*</span>
              </label>
              <span class="p-float-label">
                <InputMask
                  id="phone"
                  mask="99999999999"
                  slotChar=""
                  inputmode="numeric"
                  pattern="[0-9]*"
                  autocomplete="off"
                  aria-autocomplete="off"
                  autofocus
                  :useGrouping="false"
                  v-model="v$.phone.$model"
                  :class="{
                    'p-border-button-invalid': v$.phone.$invalid && submitted,
                  }"
                />
                <div
                  v-if="
                    (v$.phone.$invalid && submitted) ||
                    v$.phone.$pending.$response
                  "
                >
                  <small class="p-error">Numero de telefono requerido.</small>
                </div>
              </span>
            </div>
            <!--  -->

            <!-- Bank -->
            <div class="field text-start">
              <label
                for="transfer-payment-bank"
                class="flex ml-3"
                :class="{
                  'pl-3 p-error': v$.bank.$invalid && submitted,
                }"
              >
                Código del Banco Emisor <span class="text-red-500">*</span>
              </label>
              <span class="p-float-label">
                <InputMask
                  id="bank"
                  mask="9999"
                  slotChar=""
                  inputmode="numeric"
                  pattern="[0-9]*"
                  autocomplete="off"
                  aria-autocomplete="off"
                  autofocus
                  :useGrouping="false"
                  v-model="v$.bank.$model"
                  :class="{
                    'p-border-button-invalid': v$.bank.$invalid && submitted,
                  }"
                />
                <div
                  v-if="
                    (v$.bank.$invalid && submitted) ||
                    v$.bank.$pending.$response
                  "
                >
                  <small class="p-error"
                    >Código del Banco Emisor requerido.</small
                  >
                </div>
              </span>
            </div>
            <!--  -->

            <div class="flex-shrink-0 mt-5">
              <span class="p-float-label">
                <Calendar
                  id="date"
                  v-model="v$.date.$model"
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  autocomplete="off"
                  aria-autocomplete="off"
                  :class="{
                    'p-border-button-invalid': v$.date.$invalid && submitted,
                  }"
                  :maxDate="maxDate"
                  :inputClass="'label-input'"
                  class="w-calendar"
                />
                <label
                  for="transfer-payment-date"
                  :class="{
                    'pl-3 p-error': v$.date.$invalid && submitted,
                  }"
                >
                  Fecha de Pago <span class="text-red-500">*</span>
                </label>
                <div
                  v-if="
                    (v$.date.$invalid && submitted) ||
                    v$.date.$pending.$response
                  "
                >
                  <small class="p-error">Fecha de pago requerida.</small>
                </div>
              </span>
            </div>
          </div>
          <div class="field mb-4 buttons-container">
            <Button
              ref="confirm_button"
              @click="sendPaymentRequest(!v$.$invalid)"
              label="Confirmar"
              class="main mt-4"
              :loading="loading_confirm_button"
              :enabled="true"
              :disabled="loading_confirm_button"
              type="submit"
            />
          </div>
        </form>
        <div class="flex-1 inline-block m-0 p-0 pt-2">
          <div v-if="!withdraw">
            <div
              v-for="data in paymethod_selected.structure"
              :key="data.id"
              class="mb-2"
            >
              <h6 v-if="data.label" class="inline font-bold">
                {{ data.label }}:
              </h6>
              <h6 class="inline ml-2 text-700">
                <div v-html="paymethod_selected.bank_data[data.key]"></div>
              </h6>
            </div>
            <img
              src="images/QR-bancamiga-tecloT.webp"
              alt="QR_BANCAMIGA"
              width="70%"
              height="auto"
            />
          </div>
          <div v-else>
            <span class="text-center"
              >Monto a recibir: {{ ammount_to_pay }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "primevue/button";
import { requiredIf } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import moment from "moment";

export default {
  setup: () => ({ v$: useVuelidate() }),
  components: { Button },
  props: {
    paymethod_selected: {
      default: [],
    },
    credit_amount: {
      default: null,
    },
    loading_confirm_button: {
      default: false,
    },
    withdraw: {
      default: false,
    },
    required_fields: {
      default: [],
    },
  },
  data() {
    return {
      reference: null,
      phone: null,
      bank: null,
      date: moment().format("DD/MM/YYYY"),
      submitted: false,
      maxDate: new Date(),
    };
  },
  computed: {
    ammount_to_pay() {
      if (this.credit_amount && this.paymethod_selected) {
        return (
          parseFloat(
            this.credit_amount * this.paymethod_selected.convert_amount
          ).toFixed(2) + ` ${this.paymethod_selected.currency_acronym}`
        );
      }

      return 0;
    },
    real_amount_to_pay() {
      if (this.credit_amount && this.paymethod_selected) {
        return parseFloat(
          this.credit_amount * this.paymethod_selected.convert_amount
        ).toFixed(2);
      }

      return 0;
    },
    paymethod_detail() {
      return this.paymethod_selected ? this.paymethod_selected.id : null;
    },
  },
  methods: {
    sendPaymentRequest(isFormValid) {
      let request_data = {};
      this.submitted = true;

      if (!isFormValid) return;

      this.required_fields.forEach((value) => {
        if (value === "date") {
          request_data["date"] = moment(this[value], "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          );
        } else {
          request_data[value] = this[value];
        }
      });

      this.$emit("sendPaymentRequest", request_data);
    },
    sendSMSCode(isFormValid) {
      let request_data = {};
      this.submitted = true;

      if (!isFormValid) return;

      this.required_fields.forEach((value) => {
        if (value === "date") {
          request_data["date"] = moment(this[value], "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          );
        } else {
          request_data[value] = this[value];
        }
      });

      this.$emit("sendSMS", request_data);
    },
  },
  watch: {
    paymethod_selected: {
      handler: function (val) {
        if (val) {
          setTimeout(() => {
            this.$refs.confirm_button.$el.scrollIntoView({
              block: "start",
              behavior: "smooth",
            });
          }, 100);
        }
      },
      deep: true,
    },
  },
  validations() {
    return {
      reference: {
        required: requiredIf(function () {
          return !this.withdraw;
        }),
      },
      phone: {
        required: requiredIf(function () {
          return !this.withdraw;
        }),
      },
      bank: {
        required: requiredIf(function () {
          return !this.withdraw;
        }),
      },
      date: {
        required: requiredIf(function () {
          return !this.withdraw;
        }),
      },
    };
  },
};
</script>

<style scoped>
::v-deep .label-input {
  background: transparent;
  border: none;
  border-bottom: 1px solid #7d7d7d;
  border-radius: 0px;
  font-family: "Roboto";
  font-size: 15px;
  font-weight: 400;
}
::v-deep .p-inputtext {
  border-style: none;
  border-radius: 0px;
  border-bottom: 1px solid #cc195a;
}
label {
  font-size: 0.95rem;
  font-weight: 400;
  font-family: "Roboto";
  color: #7d7d7d;
}
.btn {
  padding: 2% 10% 2% 10%;
  background: #cc195a;
  border: None;
  border-radius: 5px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  color: #ffffff;
}
.btn:hover {
  cursor: pointer;
}
.transaction-general {
  text-align: center;
}
.transaction-general input {
  width: 100%;
}

.p-float-label input:focus ~ label,
.p-float-label input.p-filled ~ label,
.p-float-label .p-inputwrapper-filled ~ label,
.p-float-label .p-inputwrapper-focus ~ label {
  background-color: transparent;
}

.w-calendar {
  width: 100%;
}

@media screen and (max-width: 650px) {
  .transaction-general form {
    max-width: 180px;
  }
}
::-webkit-input-placeholder {
  opacity: 0.5;
  color: red;
  font-weight: 100;
}

::-moz-placeholder {
  opacity: 0.5;
  color: red;
  font-weight: 100;
}

::-ms-placeholder {
  opacity: 0.5;
  color: red;
  font-weight: 100;
}

::placeholder {
  opacity: 0.5;
  color: red;
  font-weight: 100;
}
</style>
