<template>
  <div class="wallet-button-container">
    <div class="balance-info" @click="toggle" aria-haspopup="true" aria-controls="overlay_menu">
      <div class="frame-6">
        <div class="amount-label">
          {{ Number(getTotalUserBalance).toLocaleString("es-VE") }}
        </div>
      </div>
      <svg class="frame-1007" :class="{ lil_arrow: $refs.menu?.overlayVisible }" width="28" height="25"
        viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.13851 5.93834L8.44897 9.80157C8.50233 9.86381 8.56853 9.91376 8.64301 9.94801C8.71749 9.98227 8.7985 10 8.88048 10C8.96246 10 9.04348 9.98227 9.11796 9.94801C9.19244 9.91376 9.25863 9.86381 9.31199 9.80157L12.6225 5.93834C12.9384 5.56959 12.6765 5 12.1909 5H5.56907C5.08356 5 4.82163 5.56959 5.13851 5.93834Z" />
      </svg>

      <div class="symbol">
        <div class="">Bs</div>
      </div>
    </div>
    <div class="wallet-button" @click="this.$emit('show-credit-modal', true)">
      <div class="label">Wallet</div>
    </div>
  </div>
  <Menu :model="[{}]" ref="menu" id="overlay_menu" :popup="true" class="wallet-menu">
    <template #item>
      <svg class="vector" width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.723 8.12333L9.10206 0.396863C8.99533 0.272388 8.86295 0.172472 8.71398 0.103971C8.56502 0.0354681 8.403 0 8.23903 0C8.07507 0 7.91305 0.0354681 7.76408 0.103971C7.61512 0.172472 7.48273 0.272388 7.37601 0.396863L0.755085 8.12333C0.123212 8.86083 0.647088 10 1.61811 10L14.8619 10C15.8329 10 16.3567 8.86083 15.723 8.12333Z"
          fill="#242834" />
      </svg>
      <div class="frame-1012">
        <!-- <div class="frame-6">
          <div class="_0-0000000">
            {{ Number(getTotalUserBalance).toLocaleString("es-VE") }}
          </div>
        </div>
        <div class="frame-1009">
          <div class="usd">USD</div>
        </div>
        <div class="frame-1013">
          <div class="frame-1015">
            <div class="dollar-symbol">$</div>
          </div>
        </div> -->
        <div class="frame-1010">
          <div class="_0-0000000">
            {{ convert(getTotalUserBalance).toLocaleString("es-VE") }}
          </div>
        </div>
        <div class="frame-1011">
          <div class="bs">Bs</div>
        </div>
        <div class="frame-1014">
          <div class="frame-10152">
            <div class="bs2">Bs</div>
          </div>
        </div>
      </div>
    </template>
  </Menu>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  emits: ["show-credit-modal"],
  data() {
    return {
      convert_amount_ves: null,
      testClick: true,
      menu_active: false,
    };
  },
  computed: {
    ...mapState("ticketoffice", ["userBalance", "monedaOptions"]),
    ...mapGetters("ticketoffice", ["getTotalUserBalance"]),
    ...mapGetters("auth", ["role"]),
  },
  methods: {
    ...mapActions("ticketoffice", ["loadTicketOfficeSession", "getCurrencies"]),
    convert(amount) {
      let moneda = this.monedaOptions.find((e) => e.code === 1);
      let convertedValue =
        !isNaN(amount) && moneda && !isNaN(moneda.exchange_rate)
          ? Number(amount) * Number(moneda.exchange_rate)
          : Number(0);
      return convertedValue;
    },
    formatMoney(value) {
      return parseFloat(value)
        .toLocaleString(undefined, { minimumFractionDigits: 2 })
        .replace(",", ".");
    },
    toggle(event) {
      this.$refs.menu.toggle(event);
    },
  },
  created() {
    this.getCurrencies().catch();
  },
  mounted() { },
};
</script>
<style lang="scss" scoped>
.wallet-button-container {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.balance-info,
.balance-info * {
  box-sizing: border-box;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.wallet-button,
.wallet-button * {
  box-sizing: border-box;
}

.balance-info {
  background: var(--morado-oscuro, #131521);
  border-radius: 50px 0px 0px 50px;
  width: 90px;
  height: 40px;
  position: relative;
  overflow: hidden;

  .frame-6 {
    padding: 10px 10px 10px 15px;
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0px;
    top: 2px;
    width: 100%;
  }

  .amount-label {
    color: #ffffff;
    text-align: left;
    font: 600 14px "Roboto-SemiBold", sans-serif;
    position: relative;
    width: 100%;
    text-align: right;
    margin-right: 45px;
  }

  .frame-1007 {
    padding: 5px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 65px;
    top: 8px;
    overflow: visible;
  }

  .symbol {
    background: #26a17b;
    border-radius: 50px;
    padding: 2px 6px 2px 6px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 45px;
    top: calc(50% - 10px);
    color: #fff;
    font: 600 11px "Roboto-SemiBold", sans-serif;
  }

  svg {
    fill: #ffffff;
    text-align: left;
    font: 600 14px "Roboto-SemiBold", sans-serif;
    position: relative;
  }
}

.wallet-button {
  background-color: #ff824a;
  border-radius: 0px 50px 50px 0px;
  padding: 10px 20px 10px 20px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  height: 40px;
  position: relative;
  transition: background-color 0.2s;

  &:hover {
    background-color: #c5663b;
  }

  .label {
    color: #ffffff;
    text-align: left;
    font: 600 16px "Roboto-SemiBold", sans-serif;
    position: relative;
  }
}

.wallet-menu {

  .frame-1012,
  .frame-1012 * {
    box-sizing: border-box;
  }

  .frame-1012 {
    background: #242834;
    border-radius: 6px;
    width: 125px;
    height: 30px;
    position: relative;
    overflow: hidden;
  }

  .frame-6 {
    padding: 5px 10px 5px 15px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0px;
    top: 4px;
    width: 68%;
  }

  ._0-0000000 {
    color: var(--negro-texto, #fff);
    text-align: left;
    font: 600 14px "Roboto-SemiBold", sans-serif;
    position: relative;
    width: 100%;
    text-align: right;
  }

  .frame-1009 {
    padding: 5px 0px 5px 0px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 103px;
    top: 5px;
  }

  .usd {
    color: var(--negro-texto, #404040);
    text-align: left;
    font: 600 12px "Roboto-SemiBold", sans-serif;
    position: relative;
  }

  .frame-1013 {
    padding: 5px 10px 5px 5px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
    height: 26px;
    position: absolute;
    right: 0px;
    top: 4px;
  }

  .frame-1015 {
    background: #26a17b;
    border-radius: 50px;
    padding: 2px 6px 2px 6px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 15px;
    height: 15px;
    position: relative;
  }

  .dollar-symbol {
    color: var(--blanco, #ffffff);
    text-align: left;
    font: 600 10px "Roboto-SemiBold", sans-serif;
    position: relative;
    width: 6px;
    height: 11px;
  }

  .frame-1010 {
    padding: 5px 10px 5px 15px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0px;
    top: 4px;
    width: 68%;
  }

  .frame-1011 {
    padding: 5px 0px 5px 0px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 109px;
    top: 6px;
  }

  .bs {
    color: var(--negro-texto, #fff);
    text-align: left;
    font: 600 12px "Roboto-SemiBold", sans-serif;
    position: relative;
  }

  .frame-1014 {
    padding: 5px 10px 5px 5px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    height: 26px;
    position: absolute;
    right: 0px;
    top: 5px;
  }

  .frame-10152 {
    background: #f4ba2f;
    border-radius: 50px;
    padding: 2px 6px 2px 6px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 15px;
    height: 15px;
    position: relative;
  }

  .bs2 {
    color: var(--negro-texto, #fff);
    text-align: left;
    font: 600 9px "Roboto-SemiBold", sans-serif;
    position: relative;
  }

  .vector,
  .vector * {
    box-sizing: border-box;
  }

  .vector {
    width: 15.52px;
    height: 10px;
    position: absolute;
    top: -7px;
    right: 50%;
    overflow: visible;
  }
}

.lil_arrow {
  -webkit-transition: transform 0.2s;
  transition: transform 0.2s;
  transform: rotate(180deg);
}
</style>
