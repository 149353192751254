<template>
  <Message
    v-if="SystemNotification"
    :severity="SystemNotification.severity"
    :sticky="true"
    @close="$store.dispatch('changeSystemNotification', null)"
    class="system-nitification"
    >{{ SystemNotification.content }}</Message
  >
  <Toast />
  <Login v-if="$route.path === '/login'" />
  <ForgotPassword v-else-if="$route.path === '/forgot-password'" />
  <!--<Landing v-else-if="$route.path === '/landing'" />-->
  <TermsandConditions v-else-if="$route.path === '/termsandconditions'" />
  <SignUp v-else-if="$route.path === '/signup'" />
  <PwdSignUp v-else-if="$route.path === '/pwdsignup'" />
  <Error v-else-if="$route.path === '/error'" />
  <NotFound v-else-if="$route.path === '/notfound'" />
  <Access v-else-if="$route.path === '/access'" />
  <Register
    v-else-if="
      $route.path.includes('/operator-register') ||
      $route.path.includes('/marketer-register') ||
      $route.path.includes('/agency-register')
    "
  />
  <App v-else @change-theme="changeTheme" />
</template>

<script>
import EventBus from "./AppEventBus";
import App from "./App";
//import Landing from "./pages/Landing";
import TermsandConditions from "./pages/TermsandConditions";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import PwdSignUp from "./pages/PwdSignUp";
import Error from "./pages/Error";
import NotFound from "./pages/NotFound";
import Access from "./pages/Access";
import Register from "./pages/Register";

import { mapMutations, mapState } from "vuex";
import update from "./mixins/update";
import ForgotPassword from "./pages/ForgotPassword.vue";

export default {
  mixins: [update],
  mounted() {
    window.addEventListener("resize", this.resize);
    window.addEventListener("keydown", this.onKeyDown);
  },
  methods: {
    ...mapMutations("ticketoffice", [
      "setToggleTicketPreview",
      "setShowConsultClient",
      "setPayDialog",
    ]),
    onKeyDown(event) {
      if (event.code == "KeyQ" && event.altKey) {
        this.setShowConsultClient(true);
      }
      if (event.code == "KeyA" && event.altKey) {
        this.setPayDialog(true);
      }
    },
    changeTheme(event) {
      let themeElement = document.getElementById("theme-link");
      themeElement.setAttribute(
        "href",
        themeElement
          .getAttribute("href")
          .replace(this.$appState.theme, event.theme)
      );
      this.$appState.theme = event.theme;
      this.$appState.darkTheme = event.dark;
      EventBus.emit("change-theme", event);

      if (event.theme.startsWith("md")) {
        this.$primevue.config.ripple = true;
      }
    },
    resize() {
      this.$windowSize.width = window.innerWidth;
      this.$windowSize.height = window.innerHeight;
      this.closeTicketPreview();
    },
    closeTicketPreview() {
      this.setToggleTicketPreview(false);
    },
  },
  watch: {
    "$store.getters.getSystem_message": {
      deep: false,
      handler(newVal) {
        if (newVal) {
          this.$toast.removeAllGroups();
          this.$toast.add(newVal);
        }
      },
    },

    showPrint: {
      deep: false,
      handler(newVal) {
        window.removeEventListener("keydown", this.onKeyDown);
        if (!newVal) {
          window.addEventListener("keydown", this.onKeyDown);
        }
      },
    },
  },
  computed: {
    SystemNotification() {
      return this.$store.getters.getSystem_notification;
    },
    ...mapState("ticketoffice", ["showPrint"]),
  },
  components: {
    App,
    //Landing,
    TermsandConditions,
    Login,
    SignUp,
    Register,
    Error,
    NotFound,
    Access,
    PwdSignUp,
    ForgotPassword,
  },
};
</script>
