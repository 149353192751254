<template>
  <div class="style-background">
    <div
      class="flex signup-background align-content-center justify-content-center flex-wrap h-screen signup-background"
    >
      <div
        class="login-marco-padding col-12 mt-5 xl:mt-0 text-center logo-login"
      >
        <a href="#" @click.stop.prevent="goLandingPage">
          <img
            :src="'layout/images/logo-kiosqo.svg'"
            alt="logo"
            class="login-marco-logo mb-5 width-login"
          />
        </a>
      </div>
      <div
        class="flex align-items-center justify-content-center background-marco-signup font-bold border-round-2xl p-6"
      >
        <div
          class="col-12 flex align-items-center justify-content-center flex-column"
        >
          <div
            class="col-12 md:col-8 lg:col-8 pb-3 mb-6 border-700 border-bottom-1"
          >
            <form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
              <!-- Step 1 checking user DNI -->
              <div v-if="step1_email_phone && !dni_user_active" class="p-fluid">
                <div class="flex flex-column align-items-center mb-2">
                  <h4 class="text-center text-300">
                    ¿Necesitas ayuda con tu contraseña?
                  </h4>
                </div>
                <p class="text-center text-300 mb-4">
                  Ingrese su correo electrónico y número de teléfono para
                  comenzar.
                </p>
                <div class="field">
                  <div class="w-full p-float-label p-input-icon-left text-300">
                    <!-- <i class="pi pi-user text-300" />
                    <InputText
                      class="login-inputtext text-300"
                      id="cedula"
                      type="text"
                      v-model="cedula"
                      :disable="checkingDni"
                    />
                    <label class="login-float font-normal text-300" for="cedula"
                      >Cedula *</label
                    > -->
                    <span class="w-full p-float-label p-input-icon-left"
                      ><i class="pi pi-envelope text-300"></i>
                      <InputText
                        id="email"
                        v-model="v$.email.$model"
                        class="login-inputtext text-300"
                        aria-describedby="email-error"
                        aria-autocomplete="off"
                        autofocus
                      />
                      <label
                        class="login-float text-300"
                        for="email"
                        :class="{ 'p-error': v$.email.$invalid && submitted }"
                        >Correo electrónico *</label
                      >
                    </span>
                    <span v-if="v$.email.$error && submitted">
                      <span
                        id="email-error"
                        v-for="(error, index) of v$.email.$errors"
                        :key="index"
                      >
                        <small class="p-error">{{ error.$message }}</small>
                      </span>
                    </span>
                    <small
                      v-else-if="
                        (v$.email.$invalid && submitted) ||
                        v$.email.$pending.$response
                      "
                      class="p-error"
                      >{{
                        v$.email.required.$message.replace("Value", "Email")
                      }}</small
                    >

                    <span class="w-full p-float-label p-input-icon-left mt-5"
                      ><i class="pi pi-key text-300"></i>

                      <!-- <InputText
                    id="phone"
                    v-model="v$.phone.$model"
                    class="login-inputtext text-300"
                    type="number"
                    autocomplete="phone"
                  ></InputText> -->

                      <InputMask
                        id="phone"
                        mask="99999999999"
                        slotChar=""
                        inputmode="numeric"
                        pattern="[0-9]*"
                        autocomplete="off"
                        aria-autocomplete="off"
                        autofocus
                        :useGrouping="false"
                        v-model="v$.phone.$model"
                        class="login-inputtext text-300"
                      />

                      <label
                        class="login-float text-300 phone-input"
                        for="phone"
                        :class="{ 'p-error': v$.phone.$invalid && submitted }"
                        >Número de Teléfono *</label
                      >
                    </span>

                    <small
                      v-if="
                        (v$.phone.$invalid && submitted) ||
                        v$.phone.$pending.$response
                      "
                      class="p-error"
                      >{{
                        v$.phone.required.$message.replace("Value", "Phone")
                      }}</small
                    >
                  </div>

                  <div class="flex justify-content-center">
                    <Button
                      type="submit"
                      class="button-fondo text-2xl mt-3"
                      label="Continuar"
                      :disabled="!phone || !email"
                    />
                  </div>
                </div>
              </div>

              <!-- Step 2 partial user register form -->
              <div v-if="!user_not_match && step2_OTP">
                <div class="flex flex-column align-items-center mb-2">
                  <h4 class="text-center text-300">Ya casi terminamos</h4>
                </div>
                <p class="text-center font-normal text-300 mb-2">
                  Enviamos un código de validación de 6 dígitos por Whatsapp al
                  teléfono 04128786957
                </p>
                <!--  -->
                <OtpValidationForm
                  ref=" pin_form"
                  :message="``"
                  :code_digits="6"
                  :sending_validation_code="sending_validation_code"
                  @cancel="resetFormSteps"
                  @sendValidationCode="validateOTP"
                />
              </div>

              <!-- Step 3 complete user register form -->
              <div v-if="!user_not_match && step3_complete_form">
                <div class="flex flex-column align-items-center mb-2">
                  <h4 class="text-center text-300">Crea tu contraseña</h4>
                </div>
                <p class="text-center text-300 mb-4">
                  Asegúrate de no utilizar contraseñas anteriores y evita
                  colocar datos personales.
                </p>
                <div class="field">
                  <div class="w-full p-float-label p-input-icon-left text-300">
                    <Password
                      id="password"
                      v-model="v$.password.$model"
                      :class="{
                        'p-invalid': v$.password.$invalid && submitted,
                        'login-inputtext': true,
                      }"
                      toggleMask
                    >
                      <template #header>
                        <h6>Elije una contraseña</h6>
                      </template>
                      <template #footer="sp">
                        {{ sp.level }}
                        <Divider />
                        <p class="mt-2">Sugerencias</p>
                        <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                          <li>Al menos una minúscula</li>
                          <li>Al menos una mayúscula</li>
                          <li>Al menos un número</li>
                          <li>Mínimo 8 caracteres</li>
                        </ul>
                      </template>
                    </Password>
                    <label
                      class="login-float font-normal text-300"
                      for="password"
                      :class="{ 'p-error': v$.password.$invalid && submitted }"
                      >Contraseña *</label
                    >
                  </div>
                  <small
                    v-if="
                      (v$.password.$invalid && submitted) ||
                      v$.password.$pending.$response
                    "
                    class="p-error"
                    >{{
                      v$.password.required.$message.replace("Value", "Password")
                    }}</small
                  >
                </div>
                <div class="field">
                  <div class="w-full p-float-label p-input-icon-left text-300">
                    <Password
                      class="login-inputtext text-300 login-inputtext"
                      id="password2"
                      v-model="v$.password2.$model"
                      :class="{
                        'p-invalid': v$.password2.$invalid && submitted,
                      }"
                      :feedback="false"
                      toggleMask
                    >
                    </Password>
                    <label
                      class="login-float font-normal text-300"
                      for="password2"
                      :class="{ 'p-error': v$.password2.$invalid && submitted }"
                      >Confirmar contraseña *</label
                    >
                  </div>
                  <span v-if="v$.password2.$error && submitted">
                    <span
                      id="email-error"
                      v-for="(error, index) of v$.password2.$errors"
                      :key="index"
                    >
                      <small class="p-error">{{ error.$message }}</small>
                    </span>
                  </span>
                  <small
                    v-else-if="
                      (v$.password2.$invalid && submitted) ||
                      v$.password2.$pending.$response
                    "
                    class="p-error"
                    >{{
                      v$.password2.required.$message.replace(
                        "Value",
                        "Password confirmation"
                      )
                    }}</small
                  >
                </div>
                <Button
                  @click="completeRegister"
                  label="Aceptar"
                  class="button-fondo text-2xl mt-2"
                  :loading="loading"
                  :disabled="password !== password2 || !password || !password2"
                />
              </div>
            </form>
            <div class="flex text-center justify-content-center flex-wrap">
              <div class="flex align-items-center justify-content-center mt-3">
                <Button
                  label="Volver a login"
                  icon="pi pi-arrow-left"
                  class="p-button-rounded p-button-outlined font-light text-white line-height-2"
                  style="color: #cc195a; border-radius: 20px"
                  @click="goToLogin"
                >
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { email, required, sameAs } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import OtpValidationForm from "@/components/ticketoffice/forms/OtpValidationForm.vue";
import "maz-ui/css/main.css";
import messages from "../mixins/messages";
import HTTP from "@/service/http";

export default {
  setup: () => ({ v$: useVuelidate() }),
  mixins: [messages],
  components: {
    OtpValidationForm,
  },
  data() {
    return {
      phone_number: "",
      formatted_phone_number: "",
      phone_valid: false,
      email: "",
      phone: "",
      password: "",
      password2: "",
      cedula: "",
      submitted: false,
      loading: false,
      step1_email_phone: true,
      step2_OTP: false,
      step3_complete_form: false,
      validation_code: "",
      sending_validation_code: false,
    };
  },
  mounted() {
    this.resetFormSteps();
  },
  methods: {
    goToLogin() {
      window.location.href = "/login";
    },
    handleForgotPassword(isFormValid) {
      this.submitted = true;
      if (!isFormValid) return;
      this.loading = true;
      HTTP(true)
        .post("/users/recover_password", {
          email: this.email,
          phone_number: this.phone,
        })

        .then((response) => {
          if (response.status === 200) {
            this.step1_email_phone = false;
            this.step2_OTP = true;
            this.user_not_match = false;
          }
        })
        .catch((err) => {
          if (err.response) {
            this.user_not_match = true;
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail:
                err.response.data.errors &&
                err.response.data.errors[0] &&
                err.response.data.errors[0].detail
                  ? err.response.data.errors[0].detail
                  : err.response.data.message,
              life: 4000,
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    resetFormSteps() {
      this.step1_email_phone = true;
      this.step2_OTP = false;
      this.step3_complete_form = false;
      this.user_not_match = false;
      this.dni_user_active = false;
      this.cedula = "";
      this.person_names = "";
      this.person_lastnames = "";
      this.phone_number = "";
      this.formatted_phone_number = "";
      this.phone_valid = false;
      this.email = "";
      this.password = "";
      this.password2 = "";
      this.validation_code = "";
      this.accept = false;
    },
    updatePhoneNumber(value) {
      this.phone_valid = value.isValid;
    },

    handleSubmit(isFormValid) {
      this.submitted = true;
      if (!isFormValid) return;
      else if (!this.user_not_match && this.step3_complete_form) {
        this.completeRegister();
      } else {
        this.handleForgotPassword(isFormValid);
      }
    },

    validateOTP(token) {
      if (!token) return;
      this.step2_OTP = false;
      this.step3_complete_form = true;

      this.validation_code = token;
    },

    completeRegister() {
      this.submitted = true;
      this.loading = true;
      HTTP(true)
        .patch("/users/recover_password", {
          email: this.email,
          otp: this.validation_code,
          password: this.password,
          repeat_password: this.password2,
        })

        .then((response) => {
          if (response.status === 200) {
            this.goToLogin();
          }
        })
        .catch((err) => {
          if (err.response) {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail:
                err.response.data.errors &&
                err.response.data.errors[0] &&
                err.response.data.errors[0].detail
                  ? err.response.data.errors[0].detail
                  : err.response.data.message,
              life: 4000,
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    logoColor() {
      if (this.$appState.darkTheme) return "white";
      return "dark";
    },
  },
  validations() {
    if (this.step1_email_phone) {
      return {
        email: {
          required,
        },
        phone: { required },
      };
    } else if (!this.user_not_match && this.step2_OTP) {
      return {
        person_names: {
          required,
        },
        person_lastnames: {
          required,
        },
        phone_number: {
          required,
          phone: () => {
            return this.phone_valid;
          },
        },
        accept: {
          accept_terms: (value) => {
            return value === true;
          },
        },
      };
    } else
      return {
        email: {
          required,
          email,
        },
        password: {
          required,
        },
        password2: {
          required,
          sameAsPassword: sameAs(this.password),
        },
        accept: {
          accept_terms: (value) => {
            return value === true;
          },
        },
      };
  },
};
</script>
<style lang="scss" scoped>
.field {
  margin-bottom: 1.8rem;
}

.background-marco-signup {
  // background: linear-gradient(to right bottom, #fefefe00, rgb(255 255 255 / 10%));
  box-shadow: inset 0 0 0 400px rgb(255 255 255 / 5%);
  backdrop-filter: blur(10px);
}

.style-background {
  background: radial-gradient(50% 50% at 50% 50%, #461ec8 0%, #25007a 100%);
}

.signup-background {
  background: url(/public/layout/images/wallpaper-login_signup.webp);
  background-size: cover;
}

.style-message {
  background: rgb(76 175 80 / 75%) !important;
  text-shadow: #25007a 1px 1px 3px;
  padding: 0.5rem 1.5rem;
  border-radius: 0.5rem;
}

.login-form {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-direction: column;
  flex-direction: column;
}

.input-phone-label {
  position: absolute;
  color: #e0e0e0;
  top: -10px;
  left: 5px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: white;
  z-index: 4;
  font-size: 12px;
}

:deep(.maz-border) {
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.38) !important;
}

:deep(.maz-rounded-lg) {
  border-radius: 0.4rem;
  background: rgba(0, 0, 0, 0) !important;
  border: 0px solid rgba(0, 0, 0, 0.38);
}

:deep(.--is-focused .maz-border) {
  border-color: #e60000 !important;
}

:deep(.m-phone-number-input) {
  &:has(.m-input.--is-focused) .m-input-wrapper.maz-border {
    border-color: #e60000 !important;
  }
}
</style>
